<template>
  <div class="roles">
    <MobileTable
      v-model:selection="store.state.role.selectedRoles"
      :label="$route.name"
      :columns="role.columns"
      :data="store.state.role.roles"
    />
    <DesktopTable
      v-model:selection="store.state.role.selectedRoles"
      :label="$route.name"
      :columns="role.columns"
      :data="store.state.role.roles"
    />
    <Actions :items="role.actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/perfiles/nuevo");
    };
    const onModify = () => {
      if (store.state.role.selectedRoles.length !== 1) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setOpenMode", openMode.MODIFY);
        router.push("/perfiles/modificar");
      }
    };

    const role = reactive({
      columns: [
        {
          field: "description",
          header: "Nombre",
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/perfiles/nuevo"),
        },
        {
          name: "Modificar",
          action: onModify,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/perfiles/modificar"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedRoles", []);
      store.commit("setBreadcrumbs", [
        { label: "Inicio", to: "/home" },
        { label: route.name, to: route.path },
      ]);
      await store.dispatch(
        actions.rolesActions.findAll,
        store.state.auth.user.company
      );
      store.commit("setLoading", false);
    });

    return { store, role };
  },
};
</script>

<style scoped></style>
